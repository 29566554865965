<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox" style="top: 45%;">
      <div class="joinBox">
        <h1>{{ $t("btn-sign-in") }}</h1>
        <h3 style="margin-bottom:30px;">
          {{ $t("login-info-approval") }}
        </h3>
        <div class="form">
          <div class="flexB">
            <p>
              {{ $t("user-data-name") }}
              <span>*</span>
            </p>
            <input type="text" v-model="name" />
          </div>
          <div class="flexB">
            <p>
              {{ $t("user-data-email-id") }}
              <span>*</span>
            </p>
            <input type="text" v-model="loginId" />
          </div>
          <div class="flexB">
            <p>
              {{ $t("user-data-pw") }}
              <span>*</span>
            </p>
            <div>
              <input type="password" v-model="password" />
              <span>
                {{ $t("115") }}
              </span>
            </div>
          </div>
          <div class="flexB">
            <p>
              {{ $t("user-data-pw-confirm") }}
              <span>*</span>
            </p>
            <div>
              <input type="password" v-model="passwordConfirm" />
              <span
                v-if="passwordConfirm && password != passwordConfirm"
                class="errror"
                >{{ $t("118") }}</span
              >
            </div>
          </div>
          <div class="flexB">
            <p>
              {{ $t("user-data-phone-num") }}
              <span>*</span>
            </p>
            <input
              type="text"
              :value="phone"
              @input="bindNumber"
            />
          </div>
          <!-- //TODO 회사 서버에서 회원가입시 부서 목록 제거 -->
          <div class="flexB" v-if="isDepartmentShow">
            <p>
              {{ $t("user-data-dept-name") }}
              <span>*</span>
            </p>
            <select v-model="departmentId">
              <option disabled value>{{ $t("select-box-default-choose") }}</option>
              <option
                v-for="(data, i) in departmentList"
                :key="i"
                :value="data.departmentId"
              >
                {{ data.name }}
              </option>
            </select>
          </div>
          <!-- <div class="flexB">
            <p>소속부서</p>
            <input type="text" v-model="department" />
          </div> -->
          <div class="flexB">
            <p>{{ $t("user-data-work") }}</p>
            <input type="text" v-model="ownerWork" />
          </div>
        </div>
        <div class="buttonWrap">
          <button class="point large" @click="submit()" style="background:#0080FF;border:0;">{{ $t("btn-sign-in") }}</button>
          <button class="cancel large" @click="$router.go(-1)" style="border:1px solid #ddd; color:#969696;">
            {{ $t("btn-cancel") }}
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<style>
.joinBox > h1{font-size:1.4rem;}
.joinBox > h3{font-size:1.2rem; color: #969696;}
.form .flexB > div {
  width: auto !important;
}
.form .flexB{margin-bottom: 10px !important;}
</style>
<script>
// import sha256 from "js-sha256";
import { format } from "@/mixins/format";
import moment from "moment";
import Footer from "@/components/Footer";
import {fetchDepartmentList} from "@/api/department";
import {
  checkId,
} from "@/api/login";
import {
  fetchAccountList
} from "@/api/account";
import {
  createUser
} from "@/api/user";
export default {
  components: { Footer },
  mixins: [format],
  data() {
    return {
      moment: moment,
      visible: false,
      veyfyCodeVisible: false,
      accountId: "",
      userId: null,
      loginId: "",
      status: "Waiting",
      userType: "User",
      registerDate: null,
      ownerWork: "",
      phone: "",
      name: "",
      password: "",
      passwordConfirm: "",
      personDataCollectAgreeFlag: false,
      termOfUseFlag: false,
      accountList: [],
      departmentList: [],
      departmentId: "",
      idCheck: false,
      device: null,
      isDepartmentShow: false,
    };
  },
  watch : {
    phone(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.phone = val.replace(/[^0-9]/g,'');
      }
    },
  },
  mounted() {
    this.accountId = this.$route.params.accountId;
    this.device = this.$route.query.device;
    this.getAccountList();
    this.getDepartmentList();
  },
  methods: {
    getDepartmentList() {
      var params = {
        accountId : this.accountId,
        statuses : "Used"
      }
      fetchDepartmentList(params).then((res) => {
        this.departmentList = res.data.data.content;
      });
    },

    getAccountList() {
      fetchAccountList().then((res) => {
        this.accountList = res.data.data;
      });
    },
    getBirthdayMask(val) {
      let res = this.getBirthday(val);
      this.residentRegistrationNumber = res;
    },
    bindNumber(event){
      this.phone = event.target.value;
    },
    submit() {
      if (this.name == "") {
        return alert(this.$t("alert-message-check-name"));
      } else if (this.loginId == "") {
        return alert(this.$t("alert-message-check-id"));
      } else if (this.password == "") {
        return alert(this.$t("alert-message-check-pw"));
      } else if (this.password != this.passwordConfirm) {
        return alert(this.$t("alert-message-check-pw-confirm"));
      } else if (this.phone == "") {
        return alert(this.$t("alert-message-check-phone-num"));
      } else if (this.departmentId == "") {
        return alert(this.$t("alert-message-check-dept"));
      }
      //  else if (!this.termOfUseFlag) {
      //   return alert("이용약관에 동의 해주십시오.");
      // } else if (!this.personDataCollectAgreeFlag) {
      //   return alert("개인정보수집 및 이용에 동의 해주십시오.");
      // }
      if (!this.checkPasswordFormat(this.password)) {
        return alert(this.$t("alert-message-check-pw-format"));
      }
      if (!this.checkEmailFormat(this.loginId)) {
        return alert(this.$t("alert-message-check-email-format"));
      }
      if (!this.checkMobileFormat(this.phone)) {
        return alert(this.$t("alert-message-check-phone-format"));
      }

      let data = {
        accountId: this.accountId,
        password: this.password,
        loginId: this.loginId,
        phone: this.phone,
        name: this.name,
        ownerWork: this.ownerWork,
        departmentId: this.departmentId,
      };
      checkId(data.loginId, data).then((res) => {
        if (res.data.data == true) {
          createUser(data).then((res) => {
            if (res.data.result == 0) {

              var path = `/${this.accountId}/joinDone`;
              if(this.device != undefined && this.device != null && this.device != "") {
                path = path + "?device=" + this.device;
              }
              this.$router.push({
                path : path,
                params: { email: this.loginId },
              });
            } else {
              let message = res.data.message;
              alert(message);
            }
          });
        } else {
          return alert(this.$t("alert-message-check-already-id"));
        }
      });
    },
  },
};
</script>
